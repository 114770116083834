import React, { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { ToastContainer, toast } from "react-toastify";
import "./style.css";
import {  createUserWithEmailAndPassword , sendEmailVerification, updateProfile } from 'firebase/auth';
import { auth } from '../../../services/firebase';

// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
// Services
import api from "services/api.services/api.services";

function SignUp() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const [phone, setPhone] = useState(Number);
  const [country, setCountry] = useState("Cameroun");
  const history = useHistory();

  // Fetch Api
  const handleSubmit = async (e) => {
    e.preventDefault();
    const twoToneButton = document.querySelector("#btn_sign");
    if (twoToneButton) {
      twoToneButton.innerHTML = "Signing Up";
      twoToneButton.classList.add("spinning");
    } else {
      console.log("not exist");
    }
    const form = e.target;
    const username = form.username.value;
    const email = form.email.value;
    const password = form.password.value;
    const picture = "";
    console.log(username);

    createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential)  =>  {
        // Signed in
        const user = userCredential.user;
      api
        .post("auth/signup", {
          password: password,
          username: username,
          email: email,
          picture: picture,
          country: country,
          phone: phone,
        })
        .then(async (response) => {
            sendEmailVerification(userCredential.user).then(()=>{
              updateProfile(userCredential.user,{displayName:username}).then(()=>{
                auth.signOut();
                
                toast.success(
                 "An email has been sent to your register email. To active it please check your email box and click on the link",
                 {
                   position: "top-right",
                   autoClose: 3000,
                   hideProgressBar: false,
                   closeOnClick: true,
                   pauseOnHover: true,
                   draggable: true,
                   progress: 0,
                   theme: "colored",
                 }
               );
               setTimeout(
                () => {history.push('/sign-in')},
                4000
              );
               //history.push('/sign-in')

              }).catch((errosent)=>console.log("updatedisplaynam",errosent))
           }).catch((errosent)=>console.log("email verification : ",errosent))
          
         
        }).catch((error) => {
           user.delete();
          twoToneButton.classList.remove("spinning");
          twoToneButton.innerHTML = "Sign Up";
          
          if(error.response.status != null ){
            toast.error(error.response.data.message[0], {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
              theme: "colored",
            });
            return;
          }
          toast.error("an error has occurred. Check your connection and form data", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            theme: "colored",
          });
          console.log(error)
      }); 
    
    })
    .catch(async (error) => {
      twoToneButton.classList.remove("spinning");
      twoToneButton.innerHTML = "Sign Up";
        const errorCode = error.code;
       // const errorMessage = error.message;
       toast.error(errorCode, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "colored",
      });
    });
    
  };

  return (
    <>
      <DefaultAuth illustrationBackground={illustration} image={illustration}>
        <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w="100%"
          mx={{ base: "auto", lg: "0px" }}
          me="auto"
          h="100%"
          alignItems="start"
          justifyContent="center"
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "40px", md: "14vh" }}
          flexDirection="column"
        >
          <Box me="auto">
            <Heading color={textColor} fontSize="36px" mb="10px">
              Sign Up
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              Enter your details below and free sign up!
            </Text>
          </Box>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
            mb={{ base: "20px", md: "auto" }}
          >
            <form onSubmit={handleSubmit}>
              <FormControl>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Username<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  name="username"
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="text"
                  placeholder="Enter your username"
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                />
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Email<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  name="email"
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="email"
                  placeholder="Enter your email address"
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                />
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Phone<Text color={brandStars}>*</Text>
                </FormLabel>
                {/* <Input
              name="phone"
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="tel"
              placeholder=""
              mb="24px"
              fontWeight="500"
              size="lg"
            /> */}
                <PhoneInput
                  name="phone"
                  className="chakra-input css-jk88cp css-12juf3r"
                  placeholder="Enter phone number"
                  value={phone}
                  onChange={setPhone}
                  onCountryChange={setCountry}
                  defaultCountry="CM"
                />
                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                >
                  Password<Text color={brandStars}>*</Text>
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    name="password"
                    isRequired={true}
                    fontSize="sm"
                    placeholder="Min. 8 characters"
                    mb="24px"
                    size="lg"
                    type={show ? "text" : "password"}
                    variant="auth"
                  />
                  <InputRightElement
                    display="flex"
                    alignItems="center"
                    mt="4px"
                  >
                    <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: "pointer" }}
                      as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={handleClick}
                    />
                  </InputRightElement>
                </InputGroup>

                <Button
                  id="btn_sign"
                  type="submit"
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="100%"
                  h="50"
                  mb="24px"
                >
                  Sign Up
                </Button>
              </FormControl>
            </form>

            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="start"
              maxW="100%"
              mt="0px"
            >
              <Text color={textColorDetails} fontWeight="400" fontSize="14px">
                you are already register ?
                <NavLink to="/auth/sign-in">
                  <Text
                    color={textColorBrand}
                    as="span"
                    ms="5px"
                    fontWeight="500"
                  >
                    Sign in
                  </Text>
                </NavLink>
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </DefaultAuth>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default SignUp;
