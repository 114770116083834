import "./Carousel.css";
import { MdArrowCircleRight, MdArrowCircleLeft } from "react-icons/md";
import React, { useEffect, useState } from "react";

import slide1 from "../../assets/img/slide/beyond.png";
import slide2 from "../../assets/img/slide/kado.png";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { ItemContent } from "components/menu/ItemContent";

const data = [
  {
    src: slide1,
    link: "https://beyond-learning.org/",
  },
  {
    src: slide2,
    link: "https://www.kadopartners.com/",
  },
];

const Carousel = () => {
  const [slide, setSlide] = useState(0);

  const bgImageStyle = {
    backgroundImage: `url(${data[slide].src})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    minHeight: "300px",
    minWidth: "300px",
    borderRadius: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  };

  const nextSlide = () => {
    setSlide(slide === data.length - 1 ? 0 : slide + 1);
  };

  const prevSlide = () => {
    setSlide(slide === 0 ? data.length - 1 : slide - 1);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (slide === data.length - 1) {
        setSlide(0);
      } else {
        setSlide(slide + 1);
      }
    }, 5000);
    return () => clearTimeout(timer);
  }, [slide]);

  return (
      <div style={bgImageStyle}>
        <MdArrowCircleLeft className="arrow arrow-left" onClick={prevSlide} />
        <MdArrowCircleRight className="arrow arrow-right" onClick={nextSlide} />
        <span className="indicators">
          {data.map((_, index) => {
            return (
              <Link to={_.link}>
                <button
                  key={index}
                  onClick={() => setSlide(index)}
                  className={
                    slide === index
                      ? "indicator"
                      : "indicator indicator-inactive"
                  }
                />
              </Link>
            );
          })}
        </span>
      </div>
  );
};

export default Carousel;
