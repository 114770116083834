//Appel d'Api et Gestions des sessions expirer
// import axios from 'axios';
import axios from 'axios';
// import { userServices } from 'services/auth.services/user.service';

const api = axios.create({
  baseURL: "https://api.larevue.org/",
});

// const user = JSON.parse(localStorage.getItem("user"));
// const refresh_token = user.refreshToken;


// const userData = {
//   refreshToken: "",
//   token_user: "",
//   email: "",
//   photoUrl: "",
//   username: "",
// };

// api.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     const originalRequest = error.config;
//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       // Obtenir un nouveau token a partir du refresh_token
//       return api
//         .post("auth/refresh", refresh_token )
//         .then((response) => {
//           if (response.status === 201) {
//             // Mise a jour du token
//             userData.username = response.data.user.username;
//             userData.email = response.data.user.email;
//             userData.photoUrl = response.data.user.photoUrl;
//             userData.token_user = response.data.token_user;
//             userData.refreshToken = response.data.refreshToken;
//             // stockage des informations du user
//             userServices.saveUserData(userData);
//             // inclure le nouveau token dans la requete original
//             originalRequest.headers.Authorization = `Bearer ${response.data.token_user}`;
//             // retourne la requete original avec le nouveau token
//             return api(originalRequest);
//           }
//         })
//         .catch(error => {
//             // deconnecter l utilisateur
//             // userServices.logout();
//             throw error;
//         });
//     }
//     return Promise.reject(error);
//   }
//);

export default api;