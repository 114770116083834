import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdReviews,
  MdOutlineReviews,
  MdOutlineLocalOffer,
  MdAdd
} from "react-icons/md";


// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import NewRevue from "views/admin/new_post";
import RevueList from "views/admin/revue_list";
import UserProfile from "views/home/profile";
import EditorTables from "views/admin/editorTables"
import SubscriptionsTables from "views/admin/subscriptionsTables";
import RevueTables from "views/admin/revuesTables";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import SignUpCentered from "views/auth/signup";
import { userServices } from "services/auth.services/user.service";
import RevueDetail from "views/admin/revue_details";
import SearchResult from "views/home/search";
import Subscriptions from "views/home/souscription";

const routes = [
  //### ADMIN
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
    role: userServices.userRole.admin,
    display: true,
  },
  {
    name: "NFT Marketplace",
    layout: "/admin",
    path: "/nft-marketplace",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: NFTMarketplace,
    secondary: true,
    role: userServices.userRole.admin,
    display: true,
  },
  {
    name: "Editors Tables",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/editor-tables",
    component: EditorTables,
    role: userServices.userRole.admin,
    display: true,
  },
  {
    name: "Revues Tables",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/revue-tables",
    component: RevueTables,
    role: userServices.userRole.admin,
    display: true,
  },
  {
    name: "Users Tables",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/user-tables",
    component: DataTables,
    role: userServices.userRole.admin,
    display: false,
  },
  {
    name: "Subscriptions Tables",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/subscription-tables",
    component: SubscriptionsTables,
    role: userServices.userRole.admin,
    display: false,
  },

  {
    name: "Sellers Tables",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/seller-tables",
    component: DataTables,
    role: userServices.userRole.admin,
    display: false,
  },
  {
    name: "Comments Tables",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/comment-tables",
    component: DataTables,
    role: userServices.userRole.admin,
    display: false,
  },
  {
    name: "Ajouter une revue",
    layout: "/admin",
    icon: <Icon as={MdAdd} width="20px" height="20px" color="inherit" />,
    path: "/new-revue",
    component: NewRevue,
    role: userServices.userRole.admin,
    display: true,
  },
  {
    name: "Revues",
    layout: "/admin",
    icon: <Icon as={MdReviews} width="20px" height="20px" color="inherit" />,
    path: "/revue-list",
    component: RevueList,
    role: userServices.userRole.admin,
    display: true,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "/profile",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: Profile,
    role: userServices.userRole.admin,
    display: false,
  },
  {
    name: "Details",
    layout: "/admin",
    path: `/revue/:revueId`,
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: RevueDetail,
    role: userServices.userRole.admin,
    display: false,
  },


  //### EDITOR
  {
    name: "Ajouter une revue",
    layout: "/editor",
    icon: <Icon as={MdAdd} width="20px" height="20px" color="inherit" />,
    path: "/new-revue",
    component: NewRevue,
    role: userServices.userRole.editor,
    display: true,
  },
  {
    name: "Revues",
    layout: "/editor",
    icon: <Icon as={MdReviews} width="20px" height="20px" color="inherit" />,
    path: "/revue-list",
    component: RevueList,
    role: userServices.userRole.editor,
    display: true,
  },
  {
    name: "Profile",
    layout: "/editor",
    path: "/profile",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: Profile,
    role: userServices.userRole.editor,
    display: false,
  },
  {
    name: "Details",
    layout: "/editor",
    path: `/revue/:revueId`,
    icon: <Icon as={MdOutlineReviews} width="20px" height="20px" color="inherit" />,
    component: RevueDetail,
    role: userServices.userRole.editor,
    display: false,
  },


  //### USER
  {
    name: "Revues",
    layout: "/home",
    icon: <Icon as={MdReviews} width="20px" height="20px" color="inherit" />,
    path: "/revue-list",
    component: RevueList,
    role: userServices.userRole.user,
    display: true,
  },
  {
    name: "Subscriptions",
    layout: "/home",
    path: "/subscriptions",
    icon: <Icon as={MdOutlineLocalOffer} width="20px" height="20px" color="inherit" />,
    component: Subscriptions,
    role: userServices.userRole.user,
    display: true, 
  },
  {
    name: "Profile",
    layout: "/home",
    path: "/profile",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: UserProfile,
    role: userServices.userRole.user,
    display: true,
  },
  {
    name: "Details",
    layout: "/home",
    path: `/revue/:revueId`,
    icon: <Icon as={MdOutlineReviews} width="20px" height="20px" color="inherit" />,
    component: RevueDetail,
    role: userServices.userRole.user,
    display: false,
  },
  {
    name: "Results",
    layout: "/home",
    path: `/results`,
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: SearchResult,
    role: userServices.userRole.user,
    display: false,
  },

  //### AUTH
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
  {
    name: "Create Account",
    layout: "/auth",
    path: "/sign-up",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignUpCentered,
  },
];

export default routes;
