// Chakra imports
import { Box, SimpleGrid, useColorModeValue } from "@chakra-ui/react";
// Assets
import React, { useEffect, useState } from "react";
import "./style.css";
import { SearchBar } from "./searchBar/SearchBar";
import Card from "components/card/Card";
import TableTopCreators from "./component/TableTopCreators_";
import TableTopCreators_Skeleton from "./component/TableTopCreators_ Skeleton";
import tableDataTopCreators from "./variables/tableDataTopCreators.json";
import { tableColumnsTopCreators } from "./variables/tableColumnsTopCreators";
import api from "services/api.services/api.services";
import { AuthData } from "services/auth.services/AuthWrapper";

export default function EditorTables() {
  let sidebarBg = useColorModeValue("white", "navy.800");
  const [EditorData, setEditorData] = useState([]);
  const { user } = AuthData();
  const config = { headers: { Authorization: `Bearer ${user.token_user}` } };
  const [isloading, setIsloading] = useState(true);

  const fetchEditorData = async () => {
    try {
      api.get("editor/all", config).then((res) => {
        setEditorData(res.data.data);
        setIsloading(false);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchEditorData();
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <SearchBar mb={{ base: "10px", md: "26px" }} ml="22px" />

        {isloading ? (
          <TableTopCreators_Skeleton
            tableData={tableDataTopCreators}
            columnsData={tableColumnsTopCreators}
          />
        ) : (
          <TableTopCreators
            tableData={EditorData}
            columnsData={tableColumnsTopCreators}
          />
        )}
      </SimpleGrid>
    </Box>
  );
}
