let saveUserData = async (userdata) => {
  localStorage.setItem("user", JSON.stringify(userdata));
  return userdata
}

let saveUserSearch = async (searchTerm) => {
  await localStorage.setItem("searchterm", (searchTerm));
}

let logout = () => {
  localStorage.removeItem("user");
};

let islogged = () => {
  let user = JSON.parse(localStorage.getItem("user"));
  return !!user;
};

let getUserSearchTerm = () => {
  localStorage.getItem('searchterm');
}

let removeSearchTerm = () => {
  localStorage.removeItem("searchterm");
}

let userRole = {
  admin: "ADIMIN",
  user: "USER",
  editor: "EDITOR",
};
let media = {
  revue : "coverRevue",
  index : "REVUE"
}
let baseUrl = "https://api.larevue.org/media"


export const userServices = {
  saveUserData,
  saveUserSearch,
  getUserSearchTerm,
  removeSearchTerm,
  logout,
  islogged,
  userRole,
  baseUrl,
  media
};
