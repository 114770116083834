import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { HashRouter} from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import AuthProvider from "services/auth.services/AuthWrapper";
import RenderRoutes from "RenderRoutes";


ReactDOM.render(
  <AuthProvider>
    <ChakraProvider theme={theme}>
      <React.StrictMode>
        <ThemeEditorProvider>
          <HashRouter>
            <RenderRoutes />
          </HashRouter>
        </ThemeEditorProvider>
      </React.StrictMode>
    </ChakraProvider>
  </AuthProvider>,
  document.getElementById("root")
);
