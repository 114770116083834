import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { AuthData } from "services/auth.services/AuthWrapper";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import HomeLayout from "layouts/home";
import { userServices } from "services/auth.services/user.service";
import EditorLayout from "layouts/editor";

const RenderRoutes = () => {
  const { user } = AuthData();

  if (!!user && user.role === userServices.userRole.admin) {
    return (
      <Switch>
        <Route path={`/admin/`} component={AdminLayout} />
        <Redirect from="/" to="/admin/default"/>
      </Switch>
    );
  } else if (!!user && user.role === userServices.userRole.user) {
    return (
      <Switch>
         <Route path={`/home/`} component={HomeLayout} />
        <Redirect from="/" to="/home/revue-list" />
      </Switch>
    );
  } else if (!!user && user.role === userServices.userRole.editor) {
    return (
      <Switch>
         <Route path={`/editor/`} component={EditorLayout} />
        <Redirect from="/" to="/editor/new-revue" />
      </Switch>
    );
  }
  else
    return (
      <Switch>
        <Route path={`/auth`} component={AuthLayout} />
        <Redirect from="/" to="/auth/sign-in" />
      </Switch>
    );
};

export default RenderRoutes;
