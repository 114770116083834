import React from "react";
import "./style.css";

const Loading = () => {
  return (
    <>
      <div className="loadingio-spinner-eclipse-cjumf2xvq5v">
        <div className="ldio-7ucssw0ngdl">
          <div></div>
        </div>
      </div>
    </>
  );
};

export default Loading;
