export const tableColumnsTopCreators = [
  {
    Header: "Logo",
    accessor: "logo",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Actions",
    accessor: "actions",
  },
];
