import {
  Avatar,
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import React, { useMemo, useRef, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Menu from "./MainMenu";
import { AuthData } from "services/auth.services/AuthWrapper";
import api from "services/api.services/api.services";
import Card from "components/card/Card";
import "./style.css";

function TopCreatorTable(props) {
  const { columnsData, tableData } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef();
  const finalRef = useRef();

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const initialState = useMemo(() => tableData, [tableData]);

  const { user } = AuthData();
  const config = { headers: { Authorization: `Bearer ${user.token_user}` } };
  const [name, setName] = useState("");

  const handleClick = async (e) => {
    e.preventDefault();
    const twoToneButton = document.querySelector("#btn_editor");
    if (twoToneButton) {
      twoToneButton.innerHTML = "saving";
      twoToneButton.classList.add("spinning");
    } else {
      console.log("not exist");
    }

    try {
      api
        .post(
          "editor/create",
          {
            name: name,
            logo: "",
          },
          config
        )
        .then((res) => {
          setName("");
          twoToneButton.classList.remove("spinning");
          twoToneButton.innerHTML = "Save";
        });
    } catch (err) {
      console.log(err);
      twoToneButton.classList.remove("spinning");
      twoToneButton.innerHTML = "save failed";
    }
  };

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    prepareRow,
  } = tableInstance;

  const { pageIndex } = state;

  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  return (
    <>
      <Card
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
          <Flex
            align={{ sm: "flex-start", lg: "center" }}
            justify="space-between"
            w="100%"
            px="22px"
            pb="20px"
            mb="10px"
            boxShadow="0px 40px 58px -20px rgba(112, 144, 176, 0.26)"
          >
            <Text color={textColor} fontSize="xl" fontWeight="600">
              Editors
            </Text>
            <Button id="btn_editor" onClick={onOpen} variant="action">
              New Editor
            </Button>
          </Flex>
          <Table {...getTableProps()} variant="simple" color="gray.500">
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="10px"
                      key={index}
                      borderColor={borderColor}
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {column.render("Header")}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      let data = "";
                      if (cell.column.Header === "Logo") {
                        data = (
                          <Flex align="center">
                            <Avatar
                              src={cell.value}
                              w="30px"
                              h="30px"
                              me="8px"
                            />
                          </Flex>
                        );
                      } else if (cell.column.Header === "Name") {
                        data = (
                          <Text
                            color={textColorSecondary}
                            fontSize="sm"
                            fontWeight="500"
                          >
                            {cell.value}
                          </Text>
                        );
                      } else if (cell.column.Header === "Actions") {
                        data = (
                          <Box>
                            <Menu />
                          </Box>
                        );
                      }
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {data}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
      </Card>
      <Box>
        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
          Previous
        </Button>
        <span>
              page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong> {' '}
        </span>
        <Button onClick={() => nextPage()} disabled={!canNextPage}>
          Next
        </Button>
      </Box>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create an Editor</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input ref={initialRef} placeholder="Enter name" />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button onClick={handleClick} colorScheme="blue" mr={3}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default TopCreatorTable;
