// React
import React from "react";

// Style css
import "./style.css"

// Chakra
import { Box, Flex, Grid, GridItem, Square } from "@chakra-ui/react";

// Assets
import dailyBanner from "assets/img/subscription/daily.png";
import weeklyBanner from "assets/img/subscription/weekly.png";
import monthlyBanner from "assets/img/subscription/monthly.png";
import halfBanner from "assets/img/subscription/half.png";
import annualBanner from "assets/img/subscription/annual.png";

const Subscriptions = () => {
  return (
    <>
      {/*Responsive BOX */}

      <Box
        py={{ base: "100px", sm: "50px", md: "100px" }}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Flex
          align="center"
          color="white"
          bgSize="cover"
          py={{ base: "100px", md: "30px" }}
          borderRadius="30px"
        >
          <Grid
            templateColumns={{
              base: "repeat(2, 1fr)",
              sm: "repeat(1, 1fr)",
              md: "repeat(2, 1fr)",
              xl: "repeat(2, 1fr)",
              "2xl": "repeat(2, 1fr)",
            }}
            gap={8}
          >
            <GridItem w="100%">
              <Square
                flexDirection="column"
                borderRadius="30px"
                padding="30px"
                minH={{
                  base: "500px",
                  sm: "300px",
                  md: "350px",
                  xl: "400px",
                }}
                minW={{
                  base: "500px",
                  sm: "300px",
                  md: "350px",
                  xl: "400px",
                }}
                bgImage={dailyBanner}
                bgRepeat="no-repeat"
                bgSize="contain"
              ></Square>
            </GridItem>
            <GridItem w="100%">
              <Square
                flexDirection="column"
                borderRadius="30px"
                padding="30px"
                minH={{
                  base: "500px",
                  sm: "300px",
                  md: "350px",
                  xl: "400px",
                }}
                minW={{
                  base: "500px",
                  sm: "300px",
                  md: "350px",
                  xl: "400px",
                }}
                bgImage={weeklyBanner}
                bgRepeat="no-repeat"
                bgSize="contain"
              ></Square>
            </GridItem>
            <GridItem w="100%">
              <Square
                flexDirection="column"
                borderRadius="30px"
                padding="30px"
                minH={{
                  base: "500px",
                  sm: "300px",
                  md: "350px",
                  xl: "400px",
                }}
                minW={{
                  base: "500px",
                  sm: "300px",
                  md: "350px",
                  xl: "400px",
                }}
                bgImage={monthlyBanner}
                bgRepeat="no-repeat"
                bgSize="contain"
              ></Square>
            </GridItem>
            <GridItem w="100%">
              <Square
                flexDirection="column"
                borderRadius="30px"
                padding="30px"
                minH={{
                    base: "500px",
                    sm: "300px",
                    md: "350px",
                    xl: "400px",
                  }}
                  minW={{
                    base: "500px",
                    sm: "300px",
                    md: "350px",
                    xl: "400px",
                  }}
                bgImage={halfBanner}
                bgRepeat="no-repeat"
                bgSize="contain"
              ></Square>
            </GridItem>
            <GridItem w="100%">
              <Square
                flexDirection="column"
                borderRadius="30px"
                padding="30px"
                minH={{
                    base: "500px",
                    sm: "300px",
                    md: "350px",
                    xl: "400px",
                  }}
                  minW={{
                    base: "500px",
                    sm: "300px",
                    md: "350px",
                    xl: "400px",
                  }}
                bgImage={annualBanner}
                bgRepeat="no-repeat"
                bgSize="contain"
              ></Square>
            </GridItem>
          </Grid>
        </Flex>
      </Box>
    </>
  );
};

export default Subscriptions;
