// Chakra imports
import { Box, useColorModeValue, Text } from "@chakra-ui/react";
// Assets
import React, { useState, useEffect } from "react";
import "./style.css";
import api from "services/api.services/api.services";
import DragDropFiles from "components/dnd/DragDropFiles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function NewRevue() {
  // Chakra Color Mode
  const [is_last, set_is_last] = useState(false);
  const [editeur, setEditeur] = useState([]);
  const [editeurId, setEditeurId] = useState(Number);
  const [file, setFile] = useState();
  const [errors, setErrors] = useState({});

  const user = JSON.parse(localStorage.getItem("user"));

  const config = { headers: { Authorization: `Bearer ${user.token_user}` } };

  const change_is_last = () => {
    set_is_last(!is_last);
  };

  console.log(editeur);

  const blueDivBackgroundStyle = {
    height: "45vh",
    backgroundImage:
      "linear-gradient(to bottom, var(--chakra-colors-brand-400), var(--chakra-colors-brand-600))",
    position: "absolute",
    width: "100%",
    borderRadius: "20px",
  };

  const tabButtonListStyle = {
    WebkitBoxPack: "start",
    justifyContent: "flex-start",
    flexDirection: "row",
    display: "flex",
    WebkitBoxAlign: "center",
    alignItems: "center",
    placeSelf: "center",
  };

  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  const fetchEditeur = async () => {
    //Recuperer la liste des editeurs
    try {
      api.get("editor/all", config).then((response) => {
        setEditeur(response.data.data);
      });
    } catch (error) {
      console.error("Erreur lors de la récupération des editeurs :", error);
    }
  };

  useEffect(() => {
    fetchEditeur();
  }, [editeurId]);

  function handleSubmit(event) {
    event.preventDefault();

    const twoToneButton = document.querySelector("#btn_post");
    if (twoToneButton) {
      twoToneButton.innerHTML = "Submiting";
      twoToneButton.classList.add("spinning");
    } else {
      console.log("not exist");
    }
    const form = event.target;

    const elements = form.elements;

    const titre = elements.titre.value;
    const contenu = elements.contenu.value;

    //Gestion des erreurs
    const validationErrors = {};
    if (!titre.trim()) {
      validationErrors.titre = "Veuillez entrer un titre";
    }
    if (!editeurId) {
      validationErrors.editeur = "Veuillez selectionner un editeur";
    }
    if (!file) {
      validationErrors.file = "Veuillez choisir une image";
    }
    if (!contenu.trim()) {
      validationErrors.contenu = "Veuillez entrer du contenu";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      twoToneButton.classList.remove("spinning");
      return;
    }

    //soumission du formulaire
    const url = "revues/upload/cover";
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);
    const conf = {
      headers: {
        "Content-type": "multipart/form-data",
      },
    };
    try {
      api.post(url, formData, conf, config).then((responsecover) => {
        // console.log(responsecover.data.filenaame);
        api
          .post(
            "revues/create",
            {
              title: titre,
              text: contenu,
              editeurId: parseInt(editeurId),
              cover: responsecover.data.filenaame,
            },
            config
          )
          .then((response) => {
            setErrors({});
            setFile("");
            setEditeur([]);
            setEditeurId();
            form.reset();
            toast.success("Successful Submit!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: 0,
              theme: "colored",
            });
            twoToneButton.classList.remove("spinning");
            twoToneButton.innerHTML = "Submit";
          });
      });
    } catch (error) {
      twoToneButton.classList.remove("spinning");
      twoToneButton.innerHTML = "Submit";
      if (error.request) {
        toast.error("Failed to connect, Verified your Internet connexion!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
          theme: "colored",
        });
      } else if (error.response) {
        toast.error("Please enter the correct value of fields!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
          theme: "colored",
        });
      } else console.error("Erreur lors de l Ajout de revue", error);
    }
  }

  return (
    <>
      <Box>
        <div>
          <div className="contentTabComponent">
            <div style={blueDivBackgroundStyle}></div>
            <div
              style={{
                marginTop: "165px",
                zIndex: "0",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* <div style={tabButtonListStyle}>
            <button
              className="tabButton"
              tabIndex={0}
              onClick={change_is_last}
            >
              <div className={`tabButtonItem ${is_last ? "active" : ""}`}>
                <div className="tabButtonElement"></div>
                <p className="tabButtonItemText">Info</p>
              </div>
            </button>
            <button
              className={`tabButton ${!is_last ? "opacity-button" : ""}`}
              tabIndex={-1}
              onClick={change_is_last}
            >
              <div className="tabButtonItem tabButtonItemLast">
                <div className="tabButtonElement"></div>
                <p className="tabButtonItemText">Media</p>
              </div>
            </button>
          </div> */}
              <form className="contentForms" onSubmit={handleSubmit}>
                <div className="formTab">
                  {/* {!is_last && ( */}
                  <div className="formTabContainer">
                    <div className="css-ulukh4">
                      <p className="chakra-text css-8rszb0">
                        Ajouter une nouvelle revue
                      </p>
                      <div className="css-1ofqig9">
                        <div className="css-1rrbyyl">
                          <div className="chakra-stack css-vuscuc">
                            <div class="css-1doh6tj">
                              <label
                                for="titre"
                                class="chakra-form__label css-186ooiv"
                              >
                                Titre<p class="chakra-text css-72mia8"></p>
                              </label>
                              <input
                                placeholder="eg. Elegant Chair"
                                name="titre"
                                maxh="44px"
                                id="titre"
                                class="chakra-input css-1t1l5ix"
                                // value={titre}
                                // onChange={(e) => {
                                //   setTitre(e.target.value);
                                // }}
                              />
                              {errors.titre && (
                                <Text
                                  color={"red.500"}
                                  fontWeight="500"
                                  fontSize="14px"
                                  padding={"5px"}
                                >
                                  {errors.titre}
                                </Text>
                              )}
                            </div>
                            <div className="css-1doh6tj">
                              <label
                                for="editeur"
                                className="chakra-form__label css-186ooiv"
                              >
                                Editeur
                                <p className="chakra-text css-72mia8"></p>
                              </label>
                              <select
                                name="editeur"
                                id="editeur"
                                className="chakra-input css-1t1l5ix"
                                value={editeurId}
                                onChange={(e) => setEditeurId(e.target.value)}
                              >
                                <option value="" selected>
                                  Selectionner un éditeur
                                </option>
                                {editeur.map((option) => (
                                  <option
                                    key={option.editeurId}
                                    value={option.editeurId}
                                  >
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                              {errors.editeur && (
                                <Text
                                  color={"red.500"}
                                  fontWeight="500"
                                  fontSize="14px"
                                  padding={"5px"}
                                >
                                  {errors.editeur}
                                </Text>
                              )}
                            </div>
                            <div className="css-1doh6tj">
                              <label
                                for="image"
                                className="chakra-form__label css-186ooiv"
                              >
                                Selectionner une image
                                <p className="chakra-text css-72mia8"></p>
                              </label>
                              <input
                                placeholder="eg. Elegant Chair"
                                name="image"
                                maxh="44px"
                                id="image"
                                type="file"
                                onChange={handleChange}
                                className="chakra-input css-1t1l5ix"
                              ></input>
                            </div>
                            {errors.file && (
                              <Text
                                color={"red.500"}
                                fontWeight="500"
                                fontSize="14px"
                                padding={"5px"}
                              >
                                {errors.file}
                              </Text>
                            )}
                          </div>
                          <div className="chakra-stack css-vuscuc">
                            {" "}
                            <div className="css-1doh6tj">
                              <label
                                for="Description"
                                className="chakra-form__label css-186ooiv"
                              >
                                Contenu
                                <p className="chakra-text css-1mgk6sz"></p>
                              </label>
                              <textarea
                                placeholder="Short description about the revues"
                                maxh="230px"
                                style={{ height: "230px" }}
                                id="contenu"
                                className="chakra-textarea css-xtktxr"
                                // value={contenu}
                                // onChange={(e) => setContenu(e.target.value)}
                              ></textarea>
                              {errors.contenu && (
                                <Text
                                  color={"red.500"}
                                  fontWeight="500"
                                  fontSize="14px"
                                  padding={"5px"}
                                >
                                  {errors.contenu}
                                </Text>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="css-16s7o2u">
                          <button
                            id="btn_post"
                            type="submit"
                            className="chakra-button css-184xkan"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* )} */}
                  {is_last && (
                    <div className="formTabContainer">
                      <div className="css-ulukh4">
                        <p className="chakra-text css-8rszb0">Media</p>
                        <div
                          role="button"
                          tabindex="0"
                          className="dropzone css-cejpi7"
                        >
                          <DragDropFiles />
                          {/* <input
                        onChange={handleChange}
                        type="file"
                        tabindex="-1"
                        className="chakra-input css-1tacfe8"
                        style={{  
                        opacity: 0,
                        height: 220,
                        position: "absolute"
                      }}
                      ></input>
                      <button
                        type="button"
                        className="chakra-button css-1llmklb"
                      >
                        <div className="css-0">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 24 24"
                            focusable="false"
                            className="chakra-icon css-c5zb31"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path fill="none" d="M0 0h24v24H0V0z"></path>
                            <path d="M19.35 10.04A7.49 7.49 0 0012 4C9.11 4 6.6 5.64 5.35 8.04A5.994 5.994 0 000 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM19 18H6c-2.21 0-4-1.79-4-4 0-2.05 1.53-3.76 3.56-3.97l1.07-.11.5-.95A5.469 5.469 0 0112 6c2.62 0 4.88 1.86 5.39 4.43l.3 1.5 1.53.11A2.98 2.98 0 0122 15c0 1.65-1.35 3-3 3zM8 13h2.55v3h2.9v-3H16l-4-4z"></path>
                          </svg>
                          <p className="chakra-text css-cltr4i">
                            Drop your files here, or{" "}
                            <span className="chakra-text css-1wa69dw">
                              browse
                            </span>
                          </p>
                          <p className="chakra-text css-1dbbp9i">
                            PNG, JPG and GIF files are allowed
                          </p>
                          <p className="chakra-text css-1dbbp9i">
                          
                          </p>
                        </div>
                      </button> */}
                        </div>
                        <div className="css-16s7o2u">
                          <button
                            type="button"
                            className="chakra-button css-1id0lme"
                            onClick={change_is_last}
                          >
                            Prev
                          </button>
                          <button
                            type="button"
                            className="chakra-button css-dtf1yy"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <DailyTraffic />
          <PieCard />
        </SimpleGrid>
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <Tasks />
          <MiniCalendar h='100%' minW='100%' selectRange={false} />
        </SimpleGrid>
      </SimpleGrid> */}
      </Box>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}
