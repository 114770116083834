import React, { createContext, useContext } from "react";

const AuthContext = createContext();

export const AuthData = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  //const [user, setUser] = useState(null, { isAuthenticated: false });
  const user = JSON.parse(localStorage.getItem('user'))

  const logout = () => {
    localStorage.removeItem('user');
  };

  return (
    <AuthContext.Provider value={{user, logout}}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
