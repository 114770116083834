
export const columnsDataCheck = [
  {
    Header: "REVUEID",
    accessor: "revueId",
  },
  {
    Header: "TITLE",
    accessor: "title",
  },
  {
    Header: "TEXT",
    accessor: "text",
  },
  {
    Header: "EDITOR",
    accessor: "editeur.name",
  },
  {
    Header: "COVER",
    accessor: "cover",
  },
  {
    Header: "CREATEAT",
    accessor: "createdAt",
  },
  {
    Header: "UPDATEAT",
    accessor: "updateAt",
  },
];