// Chakra imports
import { Box, Text, useColorModeValue } from "@chakra-ui/react";
// Assets
import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { AuthData } from "services/auth.services/AuthWrapper";
import { userServices } from "services/auth.services/user.service";
import "./style.css";
import api from "services/api.services/api.services";
import { StarIcon } from "@chakra-ui/icons";
// Skeletton
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function RevueDetail() {
  // Chakra Color Mode
  const sidebarBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("navy.700", "white");

  const color = "#f6ad55";
  const { user } = AuthData();
  const [revue, setRevue] = useState({});
  const [seller, setSeller] = useState([]);
  const [editeur, setEditeur] = useState("");
  const [loading, setLoading] = useState(true);
  const { revueId } = useParams();
  const location = useLocation();
  console.log(location);
  console.log(revueId);
  const config = { headers: { Authorization: `Bearer ${user.token_user}` } };
  const fetchArticle = async () => {
    try {
      const response = await api.get(`revues/get/${revueId}`, config);
      if (response) {
        setRevue(response.data.data);
        setLoading(false);
        setSeller(response.data.data.editeur.seller);
        setEditeur(response.data.data.editeur.name);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchArticle();
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box>
      <div className="css-1wv9e5">
        <Box bg={sidebarBg} className="css-c90d97">
          <div className="css-1ofqig9">
            <div className="css-ctbaqr">
              <div className="css-1xah0mr">
                <div className="css-jr93ze">
                  {loading ? (
                    <Skeleton enableAnimation height="100%" />
                  ) : (
                    <img
                      src={`${userServices.baseUrl}?filename=${revue.cover}&type=${userServices.media.revue}`}
                      className="chakra-image css-1athpoa"
                    ></img>
                  )}
                </div>
              </div>
              <div className="css-j7qwjs">
                {loading ? (
                  <Skeleton enableAnimation />
                ) : (
                  <Text color={textColor} className="chakra-text css-8bclud">
                    {" "}
                    {editeur}
                  </Text>
                )}
                <div className="css-o6kj28">
                  <StarIcon color={color} />
                  <StarIcon color={color} />
                  <StarIcon color={color} />
                  <StarIcon color={color} />
                  <StarIcon color={color} />
                </div>
                {loading ? (
                  <Skeleton count={5} enableAnimation />
                ) : (
                  <>
                    <Text color={textColor} className="chakra-text css-dubuw">
                      {revue.title}
                    </Text>
                    <Text color={textColor} className="chakra-text css-dubuwf">
                      {revue.text}
                    </Text>
                  </>
                )}

                {seller.length > 0 && (
                  <div className="chakra-accordion css-1a08lwf">
                    <div className="chakra-accordion__item css-1t7rcca">
                      <button
                        type="button"
                        id="accordion-button-:rg:"
                        aria-expanded="true"
                        aria-controls="accordion-panel-:rg:"
                        className="chakra-accordion__button css-isyi3h"
                        data-index="0"
                      >
                        <div className="css-1eziwv">
                          <Text
                            color={textColor}
                            className="chakra-text css-1tx3t1v"
                          >
                            Description
                          </Text>
                        </div>
                        <svg
                          viewBox="0 0 24 24"
                          focusable="false"
                          className="chakra-icon chakra-accordion__icon css-hd9xtg"
                          aria-hidden="true"
                        >
                          <path
                            fill="currentColor"
                            d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"
                          ></path>
                        </svg>
                      </button>

                      <div className="chakra-collapse">
                        <div
                          role="region"
                          id="accordion-panel-:rg:"
                          aria-labelledby="accordion-button-:rg:"
                          className="chakra-accordion__panel css-1yhgnh7"
                        >
                          {seller.map((item) => (
                            <Text
                              color={textColor}
                              className="chakra-text css-1vr1qm"
                            >
                              {item}
                            </Text>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Box>
      </div>
    </Box>
  );
}

