// Chakra imports
import { Text, useColorModeValue } from "@chakra-ui/react";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
// Assets
import Project1 from "assets/img/profile/Project1.png";
import icon from "assets/img/subscription/panier.png";

// Custom components
import Card from "components/card/Card.js";
import React from "react";
import api from "services/api.services/api.services";
import { AuthData } from "services/auth.services/AuthWrapper";
import Project from "views/home/profile/components/Project";
import { useState, useEffect } from "react";

export default function Projects(props) {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const [subscription, setSubscription] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = AuthData();
  const config = { headers: { Authorization: `Bearer ${user.token_user}` } };

  const fetchSubcriptionUser = async () => {
    try {
      await api
        .get(`subscription/getAllSubscriptionUser`, config)
        .then((res) => {
          console.log(res.data);
          setSubscription(res.data.data);
          setLoading(false);
        });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchSubcriptionUser();
  }, []);

  return (
    <Card mb={{ base: "0px", "2xl": "20px" }}>
      <Text
        color={textColorPrimary}
        fontWeight="bold"
        fontSize="2xl"
        mt="10px"
        mb="4px"
      >
        Subscriptions history
      </Text>
      <Text color={textColorSecondary} fontSize="md" me="26px" mb="40px">
        Here you can find more details about your subscriptions.
      </Text>
      {loading ? (
        <Skeleton count={2} height='100%' enableAnimation />
      ) : (
        subscription.map((item) => (
          <Project
            boxShadow={cardShadow}
            image={icon}
            expireAt={moment(item.expireAt).endOf("hour").fromNow()}
            status={item.status}
            offre={item.offre}
            price={item.price}
          />
        ))
      )}
    </Card>
  );
}
