import React, { useState, useEffect } from "react";
import { Box, Text, useColorModeValue } from "@chakra-ui/react";
// Assets
import moment from "moment";
import "./style.css";
import Loading from "services/auth.services/Loading";
import { Link } from "react-router-dom";
import { userServices } from "services/auth.services/user.service";
import api from "services/api.services/api.services";
import { AuthData } from "services/auth.services/AuthWrapper";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SearchResult = () => {
  // Chakra mode
  let sidebarBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("navy.700", "white");

  const [items, setItems] = useState([]);
  const [estimatedTotalHits, setEstimatedTotalHits] = useState("");
  const { user } = AuthData();
  const config = { headers: { Authorization: `Bearer ${user.token_user}` } };
  const text = localStorage.getItem("searchterm");
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    try {
      await api
        .post(`search?index=${userServices.media.index}&text=${text}`, config)
        .then((res) => {
          const hits = res.data.hits;
          setItems(hits);
          setEstimatedTotalHits(res.data.estimatedTotalHits);
          setIsLoading(false);
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [text]);

  return (
    <Box>
      {isLoading ? (
        <>
          <div className="css-17qk605">
            <div className="css-awi03e">
              <div className="chakra-tabs css-13o7eu2">
                <div className="chakra-tabs__tablist css-1dsay9e">
                  <div className="css-k008qs">
                    <button
                      type="button"
                      id="tabs-:rg:--tab-0"
                      role="tab"
                      tabindex="0"
                      aria-selected="true"
                      aria-controls="tabs-:rg:--tabpanel-0"
                      className="chakra-tabs__tab css-1qdgk8"
                      data-index="0"
                    >
                      <div className="css-70qvj9">
                        <Text
                          color={textColor}
                          className="chakra-text css-9p0xzy"
                          id="text"
                        >
                          All
                        </Text>
                      </div>
                      <div className="css-ezmzth"></div>
                    </button>
                    <button
                      type="button"
                      id="tabs-:rg:--tab-1"
                      role="tab"
                      tabindex="-1"
                      aria-selected="false"
                      aria-controls="tabs-:rg:--tabpanel-1"
                      className="chakra-tabs__tab css-1rbqyc8"
                      data-index="1"
                    >
                      <div className="css-70qvj9">
                        <Text
                          color={textColor}
                          className="chakra-text css-9p0xzy"
                        >
                          Popular
                        </Text>
                      </div>
                      <div className="css-1gl6llx"></div>
                    </button>
                    <button
                      type="button"
                      id="tabs-:rg:--tab-1"
                      role="tab"
                      tabindex="-1"
                      aria-selected="false"
                      aria-controls="tabs-:rg:--tabpanel-1"
                      className="chakra-tabs__tab css-1rbqyc8"
                      data-index="1"
                    >
                      <div className="css-70qvj9">
                        <Text
                          color={textColor}
                          className="chakra-text css-9p0xzy"
                        >
                          Newest
                        </Text>
                      </div>
                      <div className="css-1gl6llx"></div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Box className="css-adm2jf">
            <Box bg={sidebarBg} className="css-nqi09">
              <div className="css-1owre1">
                <div className="css-13srga">
                  <Skeleton enableAnimation height="100%" />
                </div>
                <div className="css-k3xqq8" style={{ width: "100%" }}>
                  <div className="css-vj7xax" style={{ width: "100%" }}>
                    <div direction="column" className="css-1lzhnyw">
                      <p
                        className="chakra-text css-1svo1r5"
                        style={{ paddingBottom: "20px" }}
                      >
                        <Skeleton enableAnimation height="30px" />
                      </p>
                      <p className="chakra-text css-1svo1r5">
                        <Skeleton enableAnimation count={5} height="20px" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </Box>
          <div className="css-adm2jf">
            <Box bg={sidebarBg} className="css-nqi09">
              <div className="css-1owre1">
                <div className="css-13srga">
                  <Skeleton enableAnimation height="100%" />
                </div>
                <div className="css-k3xqq8" style={{ width: "100%" }}>
                  <div className="css-vj7xax" style={{ width: "100%" }}>
                    <div direction="column" className="css-1lzhnyw">
                      <p
                        className="chakra-text css-1svo1r5"
                        style={{ paddingBottom: "20px" }}
                      >
                        <Skeleton enableAnimation height="30px" />
                      </p>
                      <p className="chakra-text css-1svo1r5">
                        <Skeleton enableAnimation count={5} height="20px" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </div>
          <div className="css-adm2jf">
            <Box bg={sidebarBg} className="css-nqi09">
              <div className="css-1owre1">
                <div className="css-13srga">
                  <Skeleton enableAnimation height="100%" />
                </div>
                <div className="css-k3xqq8" style={{ width: "100%" }}>
                  <div className="css-vj7xax" style={{ width: "100%" }}>
                    <div direction="column" className="css-1lzhnyw">
                      <p
                        className="chakra-text css-1svo1r5"
                        style={{ paddingBottom: "20px" }}
                      >
                        <Skeleton enableAnimation height="30px" />
                      </p>
                      <p className="chakra-text css-1svo1r5">
                        <Skeleton enableAnimation count={5} height="20px" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </div>
        </>
      ) : (
        <div className="css-17qk605">
          <div className="css-awi03e">
            <div className="chakra-tabs css-13o7eu2">
              <div className="css-70qvj9">
                <Text
                  color={textColor}
                  className="chakra-text css-9p0xzy"
                  id="text"
                  style={{ padding: 15 }}
                >
                  {estimatedTotalHits > 1
                    ? `${estimatedTotalHits} Résultats pour (${text})`
                    : `${estimatedTotalHits} Résultat pour (${text})`}
                </Text>
                <p className="chakra-text css-trq20r"></p>
              </div>
              <div className="chakra-tabs__tablist css-1dsay9e">
                <div className="css-k008qs">
                  <button
                    type="button"
                    id="tabs-:rg:--tab-0"
                    role="tab"
                    tabindex="0"
                    aria-selected="true"
                    aria-controls="tabs-:rg:--tabpanel-0"
                    className="chakra-tabs__tab css-1qdgk8"
                    data-index="0"
                  >
                    <div className="css-70qvj9">
                      <Text
                        color={textColor}
                        className="chakra-text css-9p0xzy"
                        id="text"
                      >
                        All
                      </Text>
                    </div>
                    <div className="css-ezmzth"></div>
                  </button>
                  <button
                    type="button"
                    id="tabs-:rg:--tab-1"
                    role="tab"
                    tabindex="-1"
                    aria-selected="false"
                    aria-controls="tabs-:rg:--tabpanel-1"
                    className="chakra-tabs__tab css-1rbqyc8"
                    data-index="1"
                  >
                    <div className="css-70qvj9">
                      <Text
                        color={textColor}
                        className="chakra-text css-9p0xzy"
                      >
                        Popular
                      </Text>
                    </div>
                    <div className="css-1gl6llx"></div>
                  </button>
                  <button
                    type="button"
                    id="tabs-:rg:--tab-1"
                    role="tab"
                    tabindex="-1"
                    aria-selected="false"
                    aria-controls="tabs-:rg:--tabpanel-1"
                    className="chakra-tabs__tab css-1rbqyc8"
                    data-index="1"
                  >
                    <div className="css-70qvj9">
                      <Text
                        color={textColor}
                        className="chakra-text css-9p0xzy"
                      >
                        Newest
                      </Text>
                    </div>
                    <div className="css-1gl6llx"></div>
                  </button>
                </div>
              </div>
              <div className="css-8atqhb">
                  <div className="css-adm2jf">
                    <div className="css-1mmor72">
                      {items.map((item) => (
                        <Link to={`revue/${item.id}`}>
                          <Box
                            bg={sidebarBg}
                            className="css-nqi09b"
                            key={item.id}
                          >
                            <div className="css-1owre1">
                              <div className="css-13srgak">
                                {item.cover ? (
                                  <img
                                    viewBox="0 0 512 512"
                                    focusable="false"
                                    className="chakra-icon css-q6icfi"
                                    src={`${userServices.baseUrl}?filename=${item.cover}&type=${userServices.media.revue}`}
                                    alt="image"
                                  />
                                ) : (
                                  <Text
                                    color={textColor}
                                    className="chakra-text css-14bqeo6"
                                  >
                                    Chargement de l'image...
                                  </Text>
                                )}
                              </div>
                              <div className="css-k3xqq8">
                                <div className="css-vj7xax">
                                  <div
                                    direction="column"
                                    className="css-1lzhnyw"
                                  >
                                    <Text
                                      color={textColor}
                                      className="chakra-text css-14bqeo6"
                                    >
                                      {item.editeur.name}
                                    </Text>
                                    <Text
                                      color={textColor}
                                      className="chakra-text css-1svo1r5"
                                    >
                                      {item.text}
                                    </Text>
                                  </div>
                                </div>
                                <div className="css-1257dch">
                                  <Text
                                    color={textColor}
                                    className="chakra-text css-j156tp"
                                  >
                                    <Text
                                      color={textColor}
                                      className="chakra-text css-1oxmu54"
                                    >
                                      {moment(item.createdAt)
                                        .startOf("hour")
                                        .fromNow()}
                                    </Text>
                                  </Text>
                                </div>
                              </div>
                            </div>
                          </Box>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      )}
    </Box>
  );
};

export default SearchResult;
