import {
  Flex,
  Table,
  Checkbox,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Box,
} from "@chakra-ui/react";
import React, { useMemo, useEffect, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import Menu from "./MainMenu";
import { AuthData } from "services/auth.services/AuthWrapper";
import api from "services/api.services/api.services";
import { columnsDataCheck } from "../variables/columnsData";
import tableDataCheck from "../variables/tableDataCheck.json";
import CheckTable_skeleton from "../components/CheckTable_skeleton";

export default function CheckTable() {
  // Fetch API
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [meta, setMeta] = useState({});
  const [revueData, setRevueData] = useState([]);
  const { user } = AuthData();
  const config = { headers: { Authorization: `Bearer ${user.token_user}` } };

  useEffect(() => {
    fetchData();
    console.log(revueData);
  }, []);

  const fetchData = async () => {
    try {
      await api
        .get(`revues/all/${pageIndex + 1}/${hasMore}`, config)
        .then((res) => {
          setRevueData(res.data.data);
          console.log(revueData);
          setMeta(res.data.meta);
          setIsLoading(false);
          if (res.data.meta.next === null) {
            setHasMore(false);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  // ###

  const columns = useMemo(() => columnsDataCheck, [columnsDataCheck]);
  const data = useMemo(() => revueData, [revueData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    canNextPage,
    canPreviousPage,
    previousPage,
    prepareRow,
    pageOptions,
    state,
  } = tableInstance;

  const { pageIndex } = state;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  return (
    <>
      {isLoading ? (
        <CheckTable_skeleton
          columnsData={columnsDataCheck}
          tableData={tableDataCheck}
        />
      ) : (
        <>
          <Card
            direction="column"
            w="100%"
            px="0px"
            overflowX={{ sm: "scroll", lg: "hidden" }}
          >
            <Flex px="25px" justify="space-between" mb="20px" align="center">
              <Text
                color={textColor}
                fontSize="22px"
                fontWeight="700"
                lineHeight="100%"
              >
                Revues Table
              </Text>
              <Menu />
            </Flex>
            <Table
              {...getTableProps()}
              variant="simple"
              color="gray.500"
              mb="24px"
            >
              <Thead>
                {headerGroups.map((headerGroup, index) => (
                  <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                    {headerGroup.headers.map((column, index) => (
                      <Th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        pe="10px"
                        key={index}
                        borderColor={borderColor}
                      >
                        <Flex
                          justify="space-between"
                          align="center"
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          {column.render("Header")}
                        </Flex>
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody {...getTableBodyProps()}>
                {page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <Tr {...row.getRowProps()} key={index}>
                      {row.cells.map((cell, index) => {
                        let data = "";
                        if (cell.column.Header === "REVUEID") {
                          data = (
                            <Flex align="center">
                              <Checkbox
                                defaultChecked={cell.value}
                                colorScheme="brandScheme"
                                me="10px"
                              />
                              <Text
                                color={textColor}
                                fontSize="sm"
                                fontWeight="700"
                              >
                                {cell.value}
                              </Text>
                            </Flex>
                          );
                        } else if (cell.column.Header === "TITLE") {
                          data = (
                            <Flex align="center">
                              <Text
                                me="10px"
                                color={textColor}
                                fontSize="sm"
                                fontWeight="700"
                              >
                                {cell.value}
                              </Text>
                            </Flex>
                          );
                        } else if (cell.column.Header === "TEXT") {
                          data = (
                            <Text
                              noOfLines={2}
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {cell.value}
                            </Text>
                          );
                        } else if (cell.column.Header === "EDITOR") {
                          data = (
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {cell.value}
                            </Text>
                          );
                        } else if (cell.column.Header === "COVER") {
                          data = (
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {cell.value}
                            </Text>
                          );
                        } else if (cell.column.Header === "CREATEAT") {
                          data = (
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {cell.value}
                            </Text>
                          );
                        } else if (cell.column.Header === "UPDATEAT") {
                          data = (
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {cell.value}
                            </Text>
                          );
                        }
                        return (
                          <Td
                            {...cell.getCellProps()}
                            key={index}
                            fontSize={{ sm: "14px" }}
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent"
                          >
                            {data}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Card>
          <Box>
            <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
              Previous
            </Button>
            <span>
              page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
            <Button onClick={() => nextPage()} disabled={!canNextPage}>
              Next
            </Button>
          </Box>
        </>
      )}
    </>
  );
}
