import {
  Button,
  Flex,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import logoWhite from "assets/img/layout/logoWhite.png";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "services/api.services/api.services";
import { AuthData } from "services/auth.services/AuthWrapper";


export default function SidebarDocs() {
  const bgColor = "linear-gradient(135deg, #868CFF 0%, #4318FF 100%)";
  const borderColor = useColorModeValue("white", "navy.800");
  const [subscription, setSubscription] = useState("");
  const {user} = AuthData();
  const config = { headers: { Authorization: `Bearer ${user.token_user}` } };

  const fetchSubcriptionUser = async ()  => {
    try {
      await api.get(`subscription/getSubscriptionUser`, config)
      .then((res) => {
        console.log(res.data);
        setSubscription(res.data)
      })
    } catch (error) {
      console.error(error);
    } finally {}
  } 

  useEffect(() => {
    fetchSubcriptionUser();
  }, []);

  return (
    <Flex
    alignSelf='center!important'
    w='95%'
      justify='center'
      direction='column'
      align='center'
      bg={bgColor}
      borderRadius='30px'
      position='relative'
      >
      <Flex
        border='5px solid'
        borderColor={borderColor}
        bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
        borderRadius='50%'
        w='94px'
        h='94px'
        align='center'
        justify='center'
        mx='auto'
        position='absolute'
        left='50%'
        top='-47px'
        transform='translate(-50%, 0%)'>
        <Image src={logoWhite} w='40px' h='40px' />
      </Flex>
      <Flex
        direction='column'
        mb='12px'
        align='center'
        justify='center'
        px='15px'
        pt='55px'>
        <Text
          fontSize={{ base: "lg", xl: "18px" }}
          color='white'
          fontWeight='bold'
          lineHeight='150%'
          textAlign='center'
          px='10px'
          mt="10px"
          mb='6px'>
          {subscription.offre} Plan
        </Text>
        <Text
          fontSize='14px'
          color={"white"}
          fontWeight='500'
          px='10px'
          mb='6px'
          textAlign='center'>
          {subscription.status}
        </Text>
      </Flex>
      <Link to='profile'>
        <Button
          bg='whiteAlpha.300'
          _hover={{ bg: "whiteAlpha.200" }}
          _active={{ bg: "whiteAlpha.100" }}
          mb={{ sm: "16px", xl: "24px" }}
          color={"white"}
          fontWeight='regular'
          fontSize='sm'
          minW='185px'
          mx='auto'>
          Subscription history 
        </Button>
      </Link>
    </Flex>
  );
}
