// Chakra imports
import { Box, SimpleGrid } from "@chakra-ui/react";
import CheckTable from "./components/CheckTable";
import React from "react";
import { SearchBar } from "./components/SearchBar";

export default function RevueTables() {
    
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <SearchBar  mb={{ base: "10px", md: "26px" }} ml="22px" />
            <CheckTable />
      </SimpleGrid>
    </Box>
  );
}
