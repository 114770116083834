import React, { useEffect, useState } from "react";
import "./searchBar.css";
import { useHistory } from "react-router-dom";
import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

// import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { userServices } from "services/auth.services/user.service";

export function SearchBar(props) {
  // Pass the computed styles into the `__css` prop
  const { variant, background, children, placeholder, borderRadius, ...rest } =
    props;

  // Chakra Color Mode
  const searchIconColor = useColorModeValue("gray.700", "white");
  const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
  const inputText = useColorModeValue("gray.700", "gray.100");

  // handleSearchBar
  const [text, setText] = useState("");
  const history = useHistory();

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      handleSearch()
    }
  };

  const handleSearch = async () => {
    if (text === "") {
      toast.info("Enter a search term please", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "colored",
      });
      return
    } else {
      userServices.saveUserSearch(text);
      history.push('/home/results');
    }
  };

  return (
    <>
      <InputGroup w={{ base: "100%", md: "200px" }} {...rest}>
        <InputLeftElement
          children={
            <IconButton
              bg="inherit"
              borderRadius="inherit"
              _hover="none"
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
              icon={<SearchIcon color={searchIconColor} w="15px" h="15px" />}
              onClick={handleSearch}
            ></IconButton>
          }
        />
        <Input
          variant="search"
          fontSize="sm"
          bg={background ? background : inputBg}
          color={inputText}
          fontWeight="500"
          _placeholder={{ color: "gray.400", fontSize: "14px" }}
          borderRadius={borderRadius ? borderRadius : "30px"}
          placeholder={placeholder ? placeholder : "Search..."}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={handleKeyPress}
        />
      </InputGroup>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}
